const SEO = [
    {
        page: "home",
        description:
            "経験豊かなiOSエンジニアで、プログラミングとソフトウェアアーキテクチャに深い知識を有しています。サイバーエージェントで働く岩手県立大学出身のソフトウェアエンジニアで、基礎的なソフトウェア開発の知識と理論を習得しています。",
        keywords: ["伊藤淳一", "iOSエンジニア", "ソフトウェアアーキテクト"],
    },

    {
        page: "about",
        description:
            "サイバーエージェントで働くiOSエンジニアで、アプリケーション開発とソフトウェアアーキテクチャに深い知識と経験を有しています。自分で数々のアプリケーションとツールを開発し、その多くはオープンソースとして公開されています。",
        keywords: ["伊藤淳一", "iOSエンジニア", "ソフトウェアアーキテクト"],
    },

    {
        page: "articles",
        description:
            "QiitaやMediumを通じて最新の技術動向を学習しています。SwiftUIに特に注目しており、次のアプリ開発ではそれを活用したいと考えています。",
        keywords: ["伊藤淳一", "iOSエンジニア", "SwiftUI"],
    },

    {
        page: "projects",
        description:
            "ローカライズ問題を解決する「L10nLint」、Xcodeのバージョンに対応したプロジェクト開設ツール「xc」、シフト管理アプリ「シフタス」など、さまざまなプロジェクトを手がけてきました。",
        keywords: ["伊藤淳一", "iOSエンジニア", "ソフトウェアアーキテクト", "L10nLint", "xc", "シフタス"],
    },

    {
        page: "contact",
        description:
            "新しいアイデアやプロジェクトへの参加に興味があります。何かございましたら、ぜひお気軽にご連絡ください。",
        keywords: ["伊藤淳一", "iOSエンジニア", "ソフトウェアアーキテクト"],
    },
];

export default SEO;
