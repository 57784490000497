import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

import "./styles/project.css";

const Project = (props) => {
	const { logo, title, terms, description, linkText, link } = props;

	return (
		<React.Fragment>
			<div className="project">
				<Link to={link}>
					<div className="project-container">
						{logo && (
							<div className="project-logo">
								<img src={logo} alt="logo" />
							</div>
						)}
						<Title>{title}</Title>

						{ terms && <Terms>{terms}</Terms>}

						<Description>{description}</Description>

						{ link && <div className="project-link">
							<div className="project-link-icon">
								<FontAwesomeIcon icon={faLink} />
							</div>

							<div className="project-link-text">{linkText}</div>
						</div>}

					</div>
				</Link>
			</div>
		</React.Fragment>
	);
};

export default Project;

const Title = styled.div`
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	font-weight: 700;
	white-space: pre-line;
`

const Description = styled.div`
	color: var(--secondary-color);
	margin-top: 14px;
`

const Terms = styled.div`
	margin-top: 4px;
	color: var(--secondary-color);
`