type hoge = {
    title: string
}

const INFO = {
	main: {
		title: "Shimomura Kazumasa",
		name: "下村 一将",
		email: "kazu.devapp@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/_kzumu",
		github: "https://github.com/s2mr",
		linkedin: "https://www.linkedin.com/in/s2mr/",
		facebook: "https://www.facebook.com/kaz.s2mr/",
        reddit: "https://www.reddit.com/user/kzumu"
	},

	homepage: {
		name: "下村一将",
		hurigana: "Shimomura Kazumasa",
		description: `
            主にiOSエンジニアとして仕事をしています。
            サイバーエージェントに2019年に新卒で入社し、CLというライブ配信アプリに立ち上げから携わり、運用まで行っています。
            iOSアプリのいくつかの新規立ち上げを一人で行った経験があります。
            個人アプリも開発しておりユーザーに寄り添ったUXの高いアプリを開発するのが得意です。
        `,
	},

	about: {
		title: "iOS Engineer / Software Architect at CyberAgent.",
		description:
			"サイバーエージェントで働くiOSエンジニアで、アプリケーション開発とソフトウェアアーキテクチャに深い知識と経験を有しています。自分で数々のアプリケーションとツールを開発し、その多くはオープンソースとして公開されています。",
	},

	articles: {
		title: "Articles",
		description:
			"開発で得た知見や、プラクティスなどは記事として公開しています。",
	},

	projects: {
		tools: {
			title: "Swift tools",
			items: [
				{
					title: "L10nLint",
					description: "Lint tool for Localizable.strings",
					logo: null,
					linkText: "View Project",
					link: "https://github.com/s2mr/L10nLint",
				},

				{
					title: "xc",
					description:
						"Open your xcode project with Xcode of specific version",
					logo: null,
					linkText: "View Project",
					link: "https://github.com/s2mr/xc",
				},
			],
		},

		app: {
			title: "Own apps",
			description:
				"大学3年の頃から個人開発を行っており、現在では20以上のアプリをリリースしています。\nアプリ全体の平均DAUは3000を超えています。",
			items: [
				{
					title: "Shiftas+",
					description:
						"シフト管理アプリ。バックエンド含め自身で開発。GraphQLを活用したスケーラブルなアーキテクチャを実現。",
					logo: "https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/69/9e/f0/699ef026-76a9-1a99-4be5-081c82d5ca8c/AppIcon-1x_U007emarketing-0-7-0-85-220.png/460x0w.webp",
					linkText: "View Project",
					link: "https://apps.apple.com/jp/app/id1169500173",
				},
				{
					title: "TapShift",
					description: "シフト管理アプリ。",
					logo: "https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/2e/56/e4/2e56e42c-f084-9fc5-2a7a-563b1478db8c/AppIcon-1x_U007emarketing-0-6-0-85-220.jpeg/460x0w.webp",
					linkText: "View Project",
					link: "https://apps.apple.com/jp/app/id1540219001",
				},
				{
					title: "AIチャット Plus ",
					description:
						"シフト管理アプリ。バックエンド含め自身で開発。GraphQLを活用したスケーラブルなアーキテクチャを実現。",
					logo: "https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/4d/de/d5/4dded5e4-06ce-84fb-32dd-b9e23b00540a/AppIcon-1x_U007emarketing-0-7-0-85-220.png/492x0w.webp",
					linkText: "View Project",
					link: "https://apps.apple.com/jp/app/id6448994105",
				},
				{
					title: "ペアカレ",
					description:
						"二人の勤務シフトをインターネットでカンタン共有！",
					logo: "https://is1-ssl.mzstatic.com/image/thumb/Purple112/v4/12/13/e6/1213e6c9-4b8c-326f-3f8e-952db2531d2e/AppIcon-0-0-1x_U007emarketing-0-0-0-4-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.jpeg/492x0w.webp",
					linkText: "View Project",
					link: "https://apps.apple.com/jp/app/id1376462458",
				},
			],
		},

		subwork: {
			title: "External projects",
			items: [
				{
					title: "Replive Inc.",
                    terms: "2023/05 - Present",
					description: "技術選定・新規事業立ち上げ",
					logo: "",
					linkText: "View Company",
					link: "https://replive.jp/",
				},
				{
					title: "Caravelle Inc.",
                    terms: "2022/05 - Present",
					description: "iOSアプリ新規開発",
					logo: "",
					linkText: "View Project",
					link: "https://caravelle-inc.tokyo/",
				},
                {
					title: "Motoyui Inc.\nRecHub",
                    terms: "2022",
					description:
						"Webサービス新規開発\n採用特化型動画共有プラットホーム",
					logo: "",
					linkText: "View Company",
					link: "https://motoyui.com/",
				},
				{
					title: "Ricecurry Inc.\n早押し韓国クイズ！バトルオンライン ",
                    terms: "2021",
					description:
						"iOSアプリの立ち上げを行いました。Firebaseを使用したリアルタイムのマッチ対戦や、ボットとの対戦機能があります。",
					logo: "https://is1-ssl.mzstatic.com/image/thumb/Purple114/v4/d9/8c/14/d98c142b-ae2b-6628-b6db-a0c8f1cb047b/AppIcon-0-0-1x_U007emarketing-0-0-0-6-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/460x0w.webp",
					linkText: "View Project",
					link: "https://apps.apple.com/jp/app/id1544608791",
				},
				{
					title: "Arriv Inc.",
                    terms: "2020",
					description: "iOSアプリ新規開発",
					logo: "",
					linkText: "View Project",
					link: "",
				},
				{
					title: "Yenom Inc.",
                    terms: "2018",
					description:
						"アルバイト / Bitcoinウォレットアプリ保守 / BitcoinKit保守 ",
					logo: "",
					linkText: "View Project",
					link: "https://github.com/yenom",
				},
                {
					title: "Mamorio Inc.",
                    terms: "2017",
					description: "アルバイト",
					logo: "",
					linkText: "View Project",
					link: "",
				},
                {
					title: "PicApp Inc. / Teller",
                    terms: "2017",
					description: "インターンシップ",
					logo: "",
					linkText: "View Project",
					link: "",
				},
				{
					title: "Wantedly Inc.",
                    terms: "2017",
					description: "インターンシップ",
					logo: "",
					linkText: "View Project",
					link: "",
				},
				{
					title: "Milldea LLC.",
                    terms: "2016",
					description: "アルバイト",
					logo: "",
					linkText: "View Project",
					link: "",
				}
			],
		},
	},
};

export default INFO;
