const myArticles = [
	{
		date: "2023 7/7",
		title: "CL iOSチームにおけるビルド時間改善",
		description: "動画配信アプリ「CL」でiOSエンジニアをしている下村 (@s2mr)です。 CL（シーエル）はLDH所属グループ・アーティストのライブキャスト動画やMVを視聴できるサービスです。 2020年6月にリリースしてから今年で3年目を迎えるプロダクトになり、開発期間を含めると4年目になります。 一つのアプリに配信機能も含まれているため、アプリの規模は大きいものになります。 今回の記事では、ビルド時間を改善するためにCLで試してきたことをまとめてみたいと思います。",
		keywords: [],
		style: ``,
		link: "https://developers.cyberagent.co.jp/blog/archives/42548/",
		body: null,
	},
	{
		date: "2022 6/13",
		title: "若手エンジニアが解説！「CL」が高品質なプロダクトをつくるために取り組んでいる独自の施策とは",
		description: "株式会社サイバーエージェントのCL事業部では、LDHのエンタテインメントコンテンツを届けるFanTechサービス「CL（シーエル）」を開発しています。その過程で培ったノウハウを連載形式でお届け。第4回は「CL」が高品質なプロダクトをつくるために取り組んでいる独自の施策について紹介します。",
		keywords: [],
		style: ``,
		link: "https://creatorzine.jp/article/detail/3088",
		body: null,
	},
	{
		date: "2019 9/24",
		title: "クロマキー合成を使い透過動画をAR空間に表示する（iOSDC Japan 2019 セッションレポート）",
		description: "9月5日(木) 〜 9月7日(土)にかけて開催されたiOSDC Japan 2019において、サイバーエージェントは今年もダイアモンドスポンサーを務め、 5名のエンジニアが登壇いたしました。今日は登壇者の服部(@shmdevelop)に代わり「クロマキー合成を使い透過動画をAR空間に表示する」のセッションを下村(@_kzumu)が振り返ります。",
		keywords: [],
		style: ``,
		link: "https://developers.cyberagent.co.jp/blog/archives/23246/",
		body: null,
	}
];

export default myArticles;
