import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

import "./styles/project.css";

const SubworkProject = (props) => {
	const { logo, title, terms, description, linkText, link } = props;

	return (
		<React.Fragment>
			<Container>
				<Link to={link}>
					<div>
						<div style={{ display: "flex" }}>
							<Title>{title}</Title>

							{terms && <Terms>{terms}</Terms>}
						</div>

						<Description>{description}</Description>

						{link && (
							<div className="project-link">
								<div className="project-link-icon">
									<FontAwesomeIcon icon={faLink} />
								</div>

								<div className="project-link-text">
									{linkText}
								</div>
							</div>
						)}
					</div>
				</Link>
			</Container>
		</React.Fragment>
	);
};

export default SubworkProject;

const Container = styled.div`
	padding: 18px 20px;
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;

	margin-bottom: 10px;

	background: #fafafa;

	a {
		text-decoration: none;
	}

	:hover {
		background: #f0f0f0;
		opacity: 1;
		transition: background-color 0.3s ease-in-out;
	}

	:hover .project-link {
		color: var(--link-color);
		transition: color 0.3s ease-in-out;
	}
`;

const Title = styled.div`
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	font-weight: 700;
	white-space: pre-line;
`;

const Description = styled.div`
	color: var(--secondary-color);
	margin-top: 14px;
    white-space: pre-line;
`;

const Terms = styled.div`
	margin-left: auto;
	font-family: var(--secondary-font);
	color: var(--secondary-color);
`;
