import React from "react";

import Project from "./project";
import SubworkProject from "./subworkProject";

import INFO from "../../data/user";

import "./styles/allProjects.css";
import styled from "styled-components";

const AllProjects = () => {
	return (
		<Container>
			<ProjectTitle className="project-title">{INFO.projects.tools.title}</ProjectTitle>
			<div className="all-projects-container">
				{INFO.projects.tools.items.map((project, index) => (
					<div className="all-projects-project" key={index}>
						<Project
							logo={project.logo}
							title={project.title}
							description={project.description}
							linkText={project.linkText}
							link={project.link}
						/>
					</div>
				))}
			</div>

			<ProjectTitle className="project-title">{INFO.projects.app.title}</ProjectTitle>
			<ProjectSubtitle className="project-subtitle">{INFO.projects.app.description}</ProjectSubtitle>
			<div className="all-projects-container">
				{INFO.projects.app.items.map((project, index) => (
					<div className="all-projects-project" key={index}>
						<Project
							logo={project.logo}
							title={project.title}
							description={project.description}
							linkText={project.linkText}
							link={project.link}
						/>
					</div>
				))}
			</div>

			<ProjectTitle className="project-title">{INFO.projects.subwork.title}</ProjectTitle>

			<div style={{marginTop: "20px"}}>
				{INFO.projects.subwork.items.map((project, index) => (
					<div key={index}>
						<SubworkProject
							logo={project.logo}
							terms={project.terms}
							title={project.title}
							description={project.description}
							linkText={project.linkText}
							link={project.link}
						/>
					</div>
				))}
			</div>
		</Container>
	);
};

export default AllProjects;

const ProjectTitle = styled.div`
	font-size: 1.5rem;
	margin: 0;
	font-family: var(--secondary-font);
`

const ProjectSubtitle = styled.div`
	font-size: 17px;
	margin: 0;
	margin-bottom: 8px;
	white-space: break-spaces;
`

const Container = styled.div`
	margin-top: 40px;
`